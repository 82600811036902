import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import { FaMapMarkerAlt, FaPhoneAlt, FaRegBuilding } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Contact = ({companyName,email,number}) => {
  return (
    <div>&nbsp;
        <Container className='mt-4'>
            <Row  >
                <Col >
                <h1 className='d-flex justify-content-center' style={{color:"black",fontSize:"45px",letterSpacing:"0.3px",lineHeight:"1.3em",fontWeight:"600",paddingTop:"40px"}}>WE'RE HERE TO HELP YOU</h1>
        <div style={{color:"#7A7A7A",fontSize:"14px",paddingBottom:"10px"}} className='d-flex justify-content-center'>We have world class, flexible support via Call, email and hone. I guarantee that you’ll be able to have any issue resolved within 24 hours.</div>
                <ul className="list-unstyled">
                    <li className='d-flex justify-content-center'>
                        <a style={{fontSize:"14px",paddingBottom:"5px"}}><FaRegBuilding className="me-3" />{companyName}</a>
                    </li>
                    <li className='d-flex justify-content-center'>
                        <a style={{fontSize:"14px",paddingBottom:"5px",}}><FaMapMarkerAlt className="me-3" />Mylasandara, GROUND FLOOR, 74/1, ANITHA BUILDING,MAIN ROAD, Mylasandara, Bengaluru, Bengaluru Urban,Karnataka, 560059
</a>
                    </li>
                    <li className='d-flex justify-content-center'>
                        <a style={{fontSize:"14px",paddingBottom:"5px"}} ><MdEmail className="me-3" />{email}</a>
                    </li>
                    <li className='d-flex justify-content-center'>
                        <a style={{fontSize:"14px",paddingBottom:"5px"}}><FaPhoneAlt className="me-3" />{number}</a>
                    </li>
                </ul>
                </Col>
            </Row>
        </Container>
        &nbsp;
        <Container>
        <div className="container position-relative"> 
                  <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3890.4844053444453!2d77.66778027483848!3d12.811944087488937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sANITHA%20BUILDING%2CMAIN%20ROAD%2C%20Mylasandara%2C%20Bengaluru%2C%20Bengaluru%20Urban%2CKarnataka%2C%20560059!5e0!3m2!1sen!2sin!4v1723484385281!5m2!1sen!2sin" 
                 style={{border: "none",width:"100%",height:"100%",minHeight:"500px"}} 
                 allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
        </Container>
    </div>
  )
}

export default Contact