import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Cancellation = ({email}) => {
  return (
    <div>
        &nbsp;
        <Container style={{paddingBottom:"70px",paddingTop:"50px"}}>
            <Row>
                <Col>
                <p>Refund, Return and Cancellation:</p>
            <p>a) In the event, that there is an error i.e., technical glitch in the services provided by us, we may refund the entire amount, provided the reasons are genuine and proved after internal investigation by the company.</p>
            <p>b) In case of exceptional circumstances such as recharge is successful but not received by the user/player, wherein the fault may lie with the payment gateway or from our side, we will refund within a reasonable amount of time.</p>
            <p>c) Contact customer support via WhatsApp/e-mail/phone to raise your problem. Our support team will contact you immediately and resolve the problem in the shortest possible time.</p>
            <p>d) In general, the refund will be settled in 3-5 working days, however, it might take several days depending on the complexity of the issues and after verification by our bank and payment gateway partner. We will try our best to create the best user experience for you.</p>
            <p>e) Refund will not be processed if player/user violates game policy as mentioned in the “Terms of Service” and adopt various fraudulent means to exploit in the games.</p>
            <p>To know more details about refund and cancellation please contact {email}.</p>
                </Col>
            </Row>
        </Container>
        &nbsp;
    </div>
  )
}

export default Cancellation