import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import Cancellation from "./components/Cancellation";
import WithDrawalPolicy from "./components/WithDrawalPolicy";
import Contact from "./components/Contact";
import About from "./components/About";

function App() {
  return (
    <div className="App">
      <Header title={'Inaya Rummy'}/>
      <Routes>
        <Route path="/" element={<Home title={'Inaya Rummy'} email={' inayathenterprises77@gmail.com'}/>}></Route>
        <Route path = "/about-us" element= {<About title={'Inaya Rummy'}/>}></Route>
        <Route path = "/contact" element= {<Contact companyName={'INAYA ENTERPRISES'} email={' inayathenterprises77@gmail.com'} number={'+91 990 252 4901'}/>}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy title={'Inaya Rummy'} email={' inayathenterprises77@gmail.com'} url={'https://inayarummy.com'}/>}></Route>
        <Route path="/terms-conditions" element={<TermsConditions title={'Inaya Rummy'} companyName={'INAYA ENTERPRISES'} email={' inayathenterprises77@gmail.com'} number={'+91 990 252 4901'}/>}></Route>
        <Route path="/cancellation-refund-policy" element={<Cancellation  email={' inayathenterprises77@gmail.com'} />}></Route>
        <Route path="//withdrawal-policy" element={<WithDrawalPolicy title={'Inaya Rummy'}  email={' inayathenterprises77@gmail.com'} url={'https://inayarummy.com'}/>}></Route>
      </Routes>
      <Footer title={'Inaya Rummy'} companyName={'INAYA ENTERPRISES'} email={' inayathenterprises77@gmail.com'} number={'+91 990 252 4901'}/>
    </div>
  );
}

export default App;
