import React from 'react'
import { Button } from 'react-bootstrap'

const DownloadBtn = ({title}) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = "/apks/Inaya Rummy.apk";
  
    link.download = 'Inaya Rummy.apk';
 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
    <Button variant='warning' className='d-none d-lg-block no-radius-btn' onClick={handleDownload}>{title}</Button>
    </>
  )
}

export default DownloadBtn